<template>
  <v-navigation-drawer v-model="Customizer_drawer" app temporary :right="!$vuetify.rtl">
    <!---Theme Color -->
    <div class="px-4 py-4">
      <span>Tema</span>
      <v-switch v-model="$vuetify.theme.dark" class="mt-2" hide-details primary label="Dark" />
    </div>
    <v-divider></v-divider>
    <div class="px-4 py-4">
      <span>Mini Sidebar</span>
      <v-switch v-model="internalValue" hide-details class="mt-2" label="Mini variant"></v-switch>
    </div>
    <v-divider></v-divider>
    <!---Theme color -->
    <div class="px-4 py-4">
      <span>Navbar & Color</span>
      <v-item-group v-model="setThemeColor">
        <v-item v-for="tcolor in themecolors" :key="tcolor" :value="tcolor" class="mt-2">
          <template v-slot="{ active, toggle }">
            <v-avatar
              :class="active && 'v-settings__item--active'"
              :color="tcolor"
              class="v-settings__item mr-2"
              size="25"
              @click="toggle"
            />
          </template>
        </v-item>
      </v-item-group>
    </div>
    <v-divider></v-divider>
    <!---Sidebar Color -->
    <div class="px-4 py-4">
      <span>Sidebar Color</span>
      <v-item-group v-model="setSidebarColor">
        <v-item v-for="color in SidebarColors" :key="color" :value="color" class="mt-2">
          <template v-slot="{ active, toggle }">
            <v-avatar
              :class="active && 'v-sidebar_active'"
              :color="color"
              class="v-settings__item mr-2"
              size="25"
              @click="toggle"
            />
          </template>
        </v-item>
      </v-item-group>
    </div>
    <!---End Sidebar Color -->
  </v-navigation-drawer>
</template>

<script>
import CustomizerService from './CustomizerService';
import Swal from 'sweetalert2';
import Proxyable from 'vuetify/lib/mixins/proxyable';
//import fire from '@/fire';
//import { decryptData } from '@/components4x/utils/utils4x';
export default {
  name: 'Customizer',
  mixins: [Proxyable],
  data: () => ({
    customizerService: null,
    asyncload: false,
    right: false,
    sidebarColor: '#ffffff',
    SidebarColors: [
      'white',
      '#2b2b2b',
      'rgb(44, 59, 164)',
      'rgb(96, 44, 164)',
      'rgb(151, 210, 219)',
      'rgb(77, 86, 100)'
    ],
    themecolors: ['#1e88e6', '#21c1d6', '#fc4b6c', '#563dea', '#9C27b0', '#ff9800', '#1D4B23', '#1B310B']
  }),
  computed: {
    Customizer_drawer: {
      get() {
        return this.$store.state.Customizer_drawer;
      },
      set(val) {
        this.$store.commit('SET_CUSTOMIZER_DRAWER', val);
      }
    },
    setThemeColor: {
      get() {
        const color = this.$store.state.ThemeColor;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = color;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$vuetify.theme.themes[this.isLight ? 'light' : 'dark'].primary = color;
        return color;
      },
      async set(val) {
        if (val === undefined) return;
        /*const id = localStorage.getItem('lrd5qwlr674');
        if (id !== null) {
          const decryptId = decryptData(id);
          const ref = fire.firestore().collection('users');
          await ref.doc(decryptId).update({ themecolor: val });
        }*/
        this.$store.commit('SET_THEME_COLOR', val);
        await this.editarConfiguraciones();
      }
    },
    setSidebarColor: {
      get() {
        return this.$store.state.SidebarColor;
      },
      async set(val) {
        if (val === undefined) return;
        /*const id = localStorage.getItem('lrd5qwlr674');
        if (id !== null) {
          const decryptId = decryptData(id);
          const ref = fire.firestore().collection('users');
          await ref.doc(decryptId).update({ sidebarcolor: val });
        }*/
        this.$store.commit('SET_SIDEBAR_COLOR', val);
        await this.editarConfiguraciones();
      }
    }
  },
  watch: {
    async setSidebarColor(val) {
      this.$store.commit('SET_SIDEBAR_COLOR', val);
    },
    async setThemeColor(val) {
      this.$store.commit('SET_THEME_COLOR', val);
    },
    async '$vuetify.theme.dark'() {
      //val) {
      /*const id = localStorage.getItem('lrd5qwlr674');
      if (id === null) return;
      const decryptId = decryptData(id);
      const ref = fire.firestore().collection('users');
      await ref.doc(decryptId).update({ themedark: val });*/
      console.log('entra aca');
      await this.editarConfiguraciones();
    }
  },
  methods: {
    async editarConfiguraciones() {
      const res = await this.customizerService.post().execResource('editarConfiguraciones', {
        sidebarcolor: this.$store.getters.SidebarColor,
        themedark: this.$vuetify.theme.dark,
        themecolor: this.$store.getters.ThemeColor
      });
      if (!res.status) {
        await Swal.fire({
          icon: 'error',
          title: res.data,
          confirmButtonText: `OK`
        });
      }
    },
    async cerrarSesion() {
      const res = await this.customizerService.get().execResource('cerrarSesion');
      if (res.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('lrd5qwlr674');
        localStorage.removeItem('iow5csjr398');
        localStorage.removeItem('nc4rhtcc976');
        localStorage.removeItem('k3rtxqdp234');
        localStorage.removeItem('oq1rcizm635');
        await this.$store.dispatch('updateReset');
        this.$router.push({ path: '/auth/login' });
      }
    },
    async loadSettings() {
      /*const id = localStorage.getItem('lrd5qwlr674');
      if (id === null) return;

      const decryptId = decryptData(id);
      const ref = fire
        .firestore()
        .collection('users')
        .doc(decryptId);

      // eslint-disable-next-line no-unused-vars
      const observer = ref.onSnapshot(
        async (docSnapshot) => {
          if (!docSnapshot.exists) return;
          this.$vuetify.theme.dark = docSnapshot.data().themedark;
          this.$store.commit('SET_SIDEBAR_COLOR', docSnapshot.data().sidebarcolor);
          this.$store.commit('SET_THEME_COLOR', docSnapshot.data().themecolor);
          await this.$store.dispatch('updateRole', docSnapshot.data().role);
          await this.$store.dispatch('updateProfile', docSnapshot.data().profile);
        },
        (err) => {
          console.error(`Error: ${err}`);
        }
      );*/

      //---------------------------------------------------------------
      const res = await this.customizerService.get().execResource('configuraciones');
      if (res[0].activo == 1) {
        this.$vuetify.theme.dark = Number(res[0].themedark);
        this.$store.commit('SET_SIDEBAR_COLOR', res[0].sidebarcolor);
        this.$store.commit('SET_THEME_COLOR', res[0].themecolor);
        await this.$store.dispatch('updateProfile', res[0].permisos);
        await this.$store.dispatch('updateRole', res[0].role);
        await this.$store.dispatch('updatePermission', res[0].module_code);
      } else {
        this.cerrarSesion();
      }
    }
  },
  async created() {
    this.customizerService = this.$httpService(new CustomizerService(), this);
    await this.loadSettings();
  },
  mounted() {
    this.asyncload = true;
  }
};
</script>

<style lang="scss">
.v-application .v-item-group .v-sidebar_active {
  border: 1px solid black !important;
}
</style>
