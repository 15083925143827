class CustomizerService {
  constructor() {}
  resources = () => ({
    editarConfiguraciones: {
      uri: `/v1/autorizacion/configuracion-usuario`,
      method: ['post']
    },
    configuraciones: {
      uri: `/v1/autorizacion/listar-configuracion-usuario`,
      method: ['get']
    },
    cerrarSesion: {
      uri: `/v1/logout`,
      method: ['get']
    }
  });
}

export default CustomizerService;
