export default [
  {
    group: '/calendario-comites',
    model: false,
    avatar: require('@/assets/images/icon/calendario.svg'),
    title: 'CALENDARIO Y COMITÉS',
    meta: {
      code: 'gh-cal'
    },
    children: [
      {
        icon: 'mdi-developer-board',
        title: 'Comités Personales',
        to: 'evento',
        meta: {
          code: 'gh-cal-com_per'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Comités GH',
        to: 'comite',
        meta: {
          code: 'gh-cal-com_gh'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Matriz de Comités',
        to: 'comite-matriz',
        meta: {
          code: 'gh-cal-mat_com'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Actas de Reunión',
        to: 'compromiso',
        meta: {
          code: 'gh-cal-act_reu'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Proyectos',
        to: 'tarea',
        meta: {
          code: 'gh-cal-proy'
        }
      }
    ]
  },
  {
    group: '/actas',
    model: false,
    avatar: require('@/assets/images/icon/actas.svg'),
    title: 'ACTAS',
    meta: {
      code: 'gh-act'
    },
    children: [
      {
        icon: 'mdi-gauge',
        title: 'Dashboard',
        to: 'dashboard',
        meta: {
          code: 'gh-act-dash'
        }
      },
      {
        icon: 'mdi-file-document',
        title: 'Acta de Reunión',
        to: 'actas-reunion',
        meta: {
          code: 'gh-act-act_reu'
        }
      },
      {
        icon: 'mdi-file-document',
        title: 'Acta de Seguimiento',
        to: 'actas-seguimiento',
        meta: {
          code: 'gh-act-act_seg'
        }
      },
      {
        icon: 'mdi-table-large',
        title: 'Matriz AAC',
        to: 'matriz-aac',
        meta: {
          code: 'gh-act-mat_aac'
        }
      },
      {
        icon: 'mdi-table-large',
        title: 'Mis Actas',
        to: 'mis-actas',
        meta: {
          code: 'gh-act-mat_aac'
        }
      }
    ]
  },
  {
    group: '/indicadores',
    model: false,
    avatar: require('@/assets/images/icon/indicadores-gestion.svg'),
    title: 'INDICADORES DE GESTIÓN',
    meta: {
      code: 'gh-ind'
    },
    children: [
      {
        icon: 'mdi-developer-board',
        title: 'Mis Indicadores',
        to: 'matriz',
        meta: {
          code: 'gh-ind-mis_ind'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Hoja de Normalización',
        to: 'ficha-tecnica',
        meta: {
          code: 'gh-ind-hoj_norm'
        }
      }
    ]
  },
  {
    group: '/presupuesto',
    model: false,
    avatar: require('@/assets/images/icon/presupuesto.svg'),
    title: 'PRESUPUESTO',
    meta: {
      code: 'gh-pres'
    },
    children: [
      {
        icon: 'mdi-gauge',
        title: 'Dashboard',
        to: 'dashboard',
        meta: {
          code: 'gh-pres-dash'
        }
      },
      {
        icon: 'mdi-gauge',
        title: 'Dashboard Presupuesto',
        to: 'dashboard-presupuesto',
        meta: {
          code: 'gh-pres-dashpres'
        }
      },
      {
        icon: 'mdi-gauge',
        title: 'Registrar Presupuesto',
        to: 'registro',
        meta: {
          code: 'gh-pres-repre'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'OPEX Campo',
        to: 'opex-campo',
        meta: {
          code: 'gh-pres-opex-campo'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'OPEX Packing',
        to: 'opex-packing',
        meta: {
          code: 'gh-pres-opex-pck'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'OPEX Gasto Administrativo',
        to: 'opex-gasto-administrativo',
        meta: {
          code: 'gh-pres-opex-ga'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'OPEX TI',
        to: 'opex-ti',
        meta: {
          code: 'gh-pres-opex-ti'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'CAPEX',
        to: 'capex-campo',
        meta: {
          code: 'gh-pres-capex-campo'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Real Observado',
        to: 'real-observado',
        meta: {
          code: '*'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Tipos de Costo',
        to: 'tipos-gasto',
        meta: {
          code: 'gh-pres-tipo-costo'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Cuentas Contables',
        to: 'cuentas-contables',
        meta: {
          code: 'gh-pres-cuenta-contable'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Cuentas Mayores',
        to: 'cuentas-mayores',
        meta: {
          code: 'gh-pres-cuenta-mayores'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Cecos',
        to: 'cecos',
        meta: {
          code: 'gh-pres-ceco'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Articulos y Servicios',
        to: 'articulos-servicios',
        meta: {
          code: 'gh-pres-articulos-servicios'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Proyección MO',
        to: 'proyeccion-mo',
        meta: {
          code: 'gh-pres-proyeccion-mo'
        }
      }
    ]
  },
  {
    group: '/proyectos',
    model: false,
    avatar: require('@/assets/images/icon/proyectos.svg'),
    title: 'PROYECTOS',
    meta: {
      code: 'gh-proy'
    },
    children: [
      {
        icon: 'mdi-gauge',
        title: 'Crear Proyecto',
        to: 'registro',
        meta: {
          code: 'gh-proy-cre_proy'
        }
      },
      {
        icon: 'mdi-chart-timeline',
        title: 'Matriz de Seguimiento',
        to: 'matriz',
        meta: {
          code: 'gh-proy-mat_seg'
        }
      },
      {
        icon: 'mdi-chart-timeline',
        title: 'Mis Proyectos',
        to: 'general',
        meta: {
          code: 'gh-proy-mis_proy'
        }
      }
    ]
  },
  {
    group: '/okr',
    model: false,
    avatar: require('@/assets/images/icon/okr.svg'),
    title: 'OKR',
    meta: {
      code: 'gh-okr'
    },
    children: [
      {
        icon: 'mdi-developer-board',
        title: 'Dashboard',
        to: 'dashboard',
        meta: {
          code: 'gh-okr-dash'
        }
      },
      {
        icon: 'mdi-file',
        title: 'Crear OKR',
        to: 'metodologia-okr',
        meta: {
          code: 'gh-okr-cre_okr'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: 'Matriz de Seguimiento',
        to: 'matriz-seguimiento',
        meta: {
          code: 'gh-okr-mat_seg'
        }
      },
      {
        icon: 'mdi-developer-board',
        title: "OKR'S",
        to: 'matriz',
        meta: {
          code: 'gh-okr-okrs'
        }
      }
    ]
  },
  {
    group: '/documentacion',
    model: false,
    avatar: require('@/assets/images/icon/documentacion.svg'),
    title: 'DOCUMENTACIÓN',
    meta: {
      code: 'gh-doc'
    },
    children: [
      {
        icon: 'mdi-gauge',
        title: 'Explorador de archivos',
        to: 'dashboard',
        meta: {
          code: 'gh-doc-expl'
        }
      }
    ]
  },
  {
    group: '/kanban',
    model: false,
    avatar: require('@/assets/images/icon/kanban.svg'),
    title: 'KANBAN',
    meta: {
      code: 'gh-kan'
    },
    children: [
      {
        icon: 'mdi-developer-board',
        title: 'Tablero',
        to: 'tablero',
        meta: {
          code: 'gh-kan-tablero'
        }
      }
    ]
  },
  {
    group: '/permisos',
    model: false,
    avatar: require('@/assets/images/icon/icon_permisos.svg'),
    title: 'PERMISOS',
    meta: {
      code: 'gh-perm'
    },
    children: [
      {
        icon: 'mdi-account-details',
        title: 'Usuarios',
        to: 'usuarios',
        meta: {
          code: 'gh-perm-usuario'
        }
      }
    ]
  }
];
