<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon mt-2"> </span>
      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">
        <span class="mt-2"> GESTION </span>
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <div @click="showhideLogo">
      <v-app-bar-nav-icon
        @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
      />
    </div>
    <v-spacer />
    <!---right part -->
    <!---Notification -->
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-2">
          <v-badge color="red" dot :value="showBadge">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list class="scrolling">
        <h4 class="px-5 py-3 pt-2 font-weight-medium title">Notificaciones</h4>
        <v-divider></v-divider>
        <v-list-item
          @click.prevent="updateNotificationVisited(item)"
          :class="{ 'blue lighten-5': !item.visited }"
          v-for="(item, i) in notifications"
          :key="i"
        >
          <v-list-item-title>
            <div class="d-flex align-center py-3 ">
              <div class>
                <v-btn class="mr-3" depressed fab small dark :color="item.iconbg">
                  <v-icon v-if="item.ticon" dark>{{ item.icon }}</v-icon>
                  <img v-else :src="item.icon" width="30" />
                </v-btn>
              </div>
              <div>
                <h4 class="font-weight-medium mb-1">{{ item.title }}</h4>
                <span class="text--secondary caption descpart d-block" style="white-space: pre-line" v-html="item.desc">
                </span>
                <small class="text--secondary">{{ item.time | convertDates }}</small>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <v-icon>fas fa-cog</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in userprofile" :key="i" @click="menuUsuario(item.id)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from 'vuex';
import HeaderService from './services/HeaderService';
import fire from '@/fire';
import moment from 'moment';
import { decryptData } from '@/components4x/utils/utils4x';
moment.locale('es');

export default {
  name: 'Header',

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogSend: false,
    jefes: [],
    itemsJefes: [],
    asunto: '',
    mensaje: '',
    searchRes: '',
    showBadge: false,
    headerService: null,
    showLogo: true,
    showSearch: false,
    notifications: [],
    userprofile: [{ id: 'CS', title: 'Cerrar Sesión' }]
  }),

  computed: {
    ...mapState(['Sidebar_drawer'])
  },
  watch: {
    notifications(val) {
      this.showBadge = false;
      for (const object of val) {
        if (!object.visited) {
          this.showBadge = true;
          break;
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: 'SET_SIDEBAR_DRAWER'
    }),
    showhideLogo: function() {
      this.showLogo = !this.showLogo;
    },
    searchbox: function() {
      this.showSearch = !this.showSearch;
    },
    menuUsuario(id) {
      switch (id) {
        case 'CS':
          this.cerrarSesion();
          break;
      }
    },
    async cerrarSesion() {
      const res = await this.headerService.get().execResource('cerrarSesion');
      if (res.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('lrd5qwlr674');
        localStorage.removeItem('iow5csjr398');
        localStorage.removeItem('nc4rhtcc976');
        localStorage.removeItem('k3rtxqdp234');
        localStorage.removeItem('oq1rcizm635');
        await this.$store.dispatch('updateReset');
        this.$router.push({ path: '/auth/login' });
      }
    },
    async updateNotificationVisited({ id, to }) {
      // eslint-disable-next-line no-unused-vars
      const res = await fire
        .database()
        .ref('notifications')
        .child(id)
        .update({ visited: true });
      if (this.$route.path !== to) await this.$router.push({ path: to });
    }
  },
  filters: {
    convertDates(val) {
      return moment(val).calendar();
    }
  },
  async mounted() {
    this.headerService = this.$httpService(new HeaderService(), this);
    const id = localStorage.getItem('lrd5qwlr674');
    if (id === null) return;

    const decryptId = decryptData(id);
    const itemsRef = fire.database().ref('notifications');

    itemsRef
      .orderByChild('idresponsable')
      .equalTo(decryptId)
      .limitToLast(5)
      .on('value', (snapshot) => {
        let data = snapshot.val();
        if (data === null) return;
        this.notifications = [];
        Object.keys(data).forEach((key) => {
          this.notifications.push({
            id: key,
            idresponsable: data[key].idresponsable,
            title: data[key].title,
            iconbg: data[key].iconbg,
            icon: data[key].icon,
            desc: data[key].desc,
            to: data[key].to,
            visited: data[key].visited,
            time: data[key].time
          });
        });

        this.notifications.sort((a, b) => {
          if (moment(a.time).format('YYYY-MM-DD HH:mm:ss') < moment(b.time).format('YYYY-MM-DD HH:mm:ss')) return 1;
          if (moment(a.time).format('YYYY-MM-DD HH:mm:ss') > moment(b.time).format('YYYY-MM-DD HH:mm:ss')) return -1;
          return 0;
        });
      });
  }
};
</script>

<style lang="scss">
.scrolling {
  overflow-y: scroll;
  height: 600px;
}
.scrolling::-webkit-scrollbar {
  width: 10px;
  background-color: #fdfdfe;
}

.scrolling::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fdfdfe;
}
.scrolling::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #dfdfe8;
}
.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
